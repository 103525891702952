export default (context) => {
  const visibility = {
    store() {
      context.store.commit('browser/setVisibility', !document.hidden);
    },
    onChange() {
      this.store();
    },
    addEventListeners() {
      window.addEventListener('visibilitychange', this.onChange.bind(this), false);
    },
    init() {
      this.store();
      this.addEventListeners();
    },
  };

  visibility.init();
};
