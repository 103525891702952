import { render, staticRenderFns } from "./CookieOverlay.vue?vue&type=template&id=304c0f06&scoped=true&"
import script from "./CookieOverlay.vue?vue&type=script&lang=js&"
export * from "./CookieOverlay.vue?vue&type=script&lang=js&"
import style0 from "./CookieOverlay.vue?vue&type=style&index=0&id=304c0f06&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304c0f06",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: require('/workspace/components/atoms/Checkbox.vue').default,BaseButton: require('/workspace/components/atoms/BaseButton.vue').default,BaseIcon: require('/workspace/components/atoms/BaseIcon.vue').default})
