
import { mapGetters } from 'vuex';

export default {
    name: 'DefaultLayout',
    scrollPosition: null,
    computed: {
        ...mapGetters({
            renderPreloader: 'preloader/use',
        }),
        footerData() {
            return this.$store.state.siteData.footer;
        },
    },
};
