import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e5659052&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheFooter: require('/workspace/components/molecules/TheFooter.vue').default,CustomCursor: require('/workspace/components/atoms/CustomCursor.vue').default,CookieOverlay: require('/workspace/components/molecules/CookieOverlay.vue').default,ThePreloader: require('/workspace/components/molecules/ThePreloader.vue').default})
