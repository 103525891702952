
const COOKIE_VERSION = 1;
const COOKIE_KEY = 'gdpr_consent';
const COOKIE_ATTRIBUTES = {
    secure: !process.env.DEV,
    sameSite: 'strict',
};

export default {
    data() {
        return {
            open: false,
            extended: false,
            marketingAccepted: false,
            metaInit: false,
        };
    },
    mounted() {
        const cookieValue = this.$cookies.get(COOKIE_KEY);

        if (!cookieValue || cookieValue.v !== COOKIE_VERSION) {
            this.open = true;
        } else if (cookieValue) {
            this.marketingAccepted = cookieValue.types.marketing;

            if (cookieValue.types.marketing) {
                this.initMetaPixel();
            }
        }
    },
    methods: {
        saveSettings() {
            this.setGdprCookie({
                essential: true,
                marketing: this.marketingAccepted,
            });
        },
        acceptAll() {
            this.setGdprCookie({
                essential: true,
                marketing: true,
            });
        },
        setGdprCookie(types) {
            this.open = false;

            this.$cookies.set(
                COOKIE_KEY,
                {
                    v: COOKIE_VERSION,
                    types,
                },
                COOKIE_ATTRIBUTES
            );

            this.initMetaPixel();
        },
        initMetaPixel() {
            if (!this.metaInit) {
                this.$metaPixel.init();
                this.metaInit = true;
            }
        },
    },
};
