// import Cookies from 'js-cookie';

import { breakpoints, breakpointsById } from '@/constants/Breakpoints';
import containers from '@/constants/Containers';

import siteData from '@/data/en';

export const COOKIE_NAME = 'cookie-consent';

export const state = () => ({
  isReady: null,
  breakpoints,
  breakpointsById,
  containers,
  cookiesAccepted: null,
  siteData,
  hideFooter: false,
  usingLocomotive: false,
});

export const getters = {
  // breakpoint: (state) => {
  //   const vw = state.viewport.width;

  //   return breakpoints.find((bp) => bp.value <= vw);
  // },
  // isMobileDevice(state) {
  //   return !!state.deviceDetect?.mobile;
  // },
  // useLocomotive(state, getters) {
  //   return !getters.isMobileDevice;
  // },
  scrollingElement(state, getters) {
    return state.usingLocomotive ? '.scroll-container' : null;
  },
  env() {
    return process.env.NODE_ENV;
  },
  isDev(state, getters) {
    return getters.env === 'development';
  },
};

export const mutations = {
  isReady(state) {
    state.isReady = true;
  },
  acceptCookies(state, value = true) {
    state.cookiesAccepted = !!value;
  },
  hasSubscribed(state) {
    state.subscribed = true;
  },
  usingLocomotive(state) {
    state.usingLocomotive = true;
  },
};

export const actions = {
  // getCookieConsent({ commit }) {
  //   if (process.client) {
  //     const accepted = Cookies.get(COOKIE_NAME) || false;
  //     commit('acceptCookies', accepted);
  //   }
  // },
  // acceptCookies({ commit }) {
  //   if (process.client) {
  //     commit('acceptCookies');
  //     Cookies.set(COOKIE_NAME, JSON.stringify(true), {
  //       expires: 365 * 5, // 5 year
  //     });
  //   }
  // },
};
