
export default {
    data() {
        return {
            x: 0,
            y: 0,
        };
    },
    lastEvent: null,
    computed: {
        style() {
            return {
                '--x': `${this.x}px`,
                '--y': `${this.y}px`,
            };
        },
    },
    mounted() {
        window.addEventListener('mousemove', this.onMouseMove);
    },
    methods: {
        onMouseMove(e) {
            this.$options.lastEvent = e;

            window.requestAnimationFrame(this.tick);
        },
        tick() {
            // console.log(this.$options.lastEvent);
            this.x = this.$options.lastEvent.clientX;
            this.y = this.$options.lastEvent.clientY;
        },
    },
};
