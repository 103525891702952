// import Vue from 'vue';
import cdnURL from '@/utils/cdnURL';
import unique from '@/utils/array/unique';

const first = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[0];
};

const last = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[arr.length - 1];
};

export default (context, inject) => {
  // Inject in Vue, context and store.
  inject('first', first);
  inject('last', last);
  inject('unique', unique);
  inject('cdnURL', cdnURL);
  inject('relativeUrl', (uri) => {
    return `/${uri}`;
    // if (uri === '__home__') {
    //   return `/${context.store.getters.locale}`;
    // }
    // return `/${context.store.getters.locale}/${uri}`;
  });
};
