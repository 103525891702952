
import { SplitText } from 'gsap/SplitText';
import { bool, number, string } from 'vue-types';

const DEFAULT_GLITCHES = 'REVEALSSCANWEARCLOTHING';
// the number of chars should match the number of keyframes in the 'glitch-chars' animation
// so: make it easily divisble
const GLITCH_CHAR_COUNT = 10;

/**
 * Glitch effect taken from: https://codepen.io/jh3y/pen/mdyymOR
 */
export default {
    props: {
        splitSelector: string().def('.split'),
        glitches: string().def(DEFAULT_GLITCHES),
        intro: bool().def(false),
        minIterations: number().def(3),
        maxIterations: number().def(6),
    },
    computed: {
        glitchChars() {
            return this.glitches.split('');
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setup();
        });
    },
    methods: {
        setup() {
            const elToSplit = this.$el.querySelector(this.splitSelector);

            if (!elToSplit) {
                console.warn('No matching element to split. Selector: ', this.splitSelector);
                return;
            }

            const splitText = new SplitText(elToSplit, {
                type: 'words, chars',
                wordsClass: 'word',
                charsClass: 'char',
            });

            const glitchLength = this.glitchChars.length;

            splitText.chars.forEach((char) => {
                char.setAttribute('data-char', char.innerText);

                // random amount of char iterations
                const iterations = Math.floor(
                    Math.random() * (this.maxIterations - this.minIterations) + this.minIterations
                );
                char.style.setProperty('--count', iterations);

                for (let i = 0; i < GLITCH_CHAR_COUNT; i++) {
                    const randomIndex = Math.floor(Math.random() * glitchLength);
                    const randomChar = this.glitchChars[randomIndex];
                    char.style.setProperty(`--char-${i}`, `"${randomChar}"`);
                }
            });
        },
    },
};
