function killScrollTriggerTweens(tweens) {
  tweens.forEach((tween) => {
    tween.scrollTrigger?.kill();
    // the actual tween/timeline
    tween.kill();
  });
}

export default {
  scrollTriggers: [],
  data() {
    return {
      createScrollTriggersOnMount: true,
    };
  },
  computed: {
    scrollingElement() {
      return this.$store.getters.scrollingElement;
    },
  },
  beforeDestroy() {
    const tweens = this.$options.scrollTriggers;

    // wait a bit before killing scrolltriggers
    // this way we won't see visible jumping of elements just before the page transition overlay comes in
    setTimeout(() => {
      killScrollTriggerTweens(tweens);
    }, 200);
  },
  mounted() {
    if (this.createScrollTriggersOnMount) {
      this.$nextTick(() => {
        try {
          this.setupScrollTriggers();
        } catch (error) {
          console.error('Could not create scrolltriggers', error);
        }
      });
    }
  },
  methods: {
    addScrollTrigger(tween) {
      this.$options.scrollTriggers.push(tween);
    },
    killScrollTriggers() {
      killScrollTriggerTweens(this.$options.scrollTriggers);
    },
  },
};
