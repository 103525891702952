
import { string, object, bool } from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';

export default {
    mixins: [buttonOrLink],
    props: {
        icon: string(),
        iconProps: object(),
        label: string(),
        plain: bool().def(false),
    },
    computed: {
        classes() {
            return [this.computedTheme, this.useHoverShape ? 'use-hs' : null];
        },
    },
};
