
import { oneOf } from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';

export const THEME_PRIMARY = 'primary';
export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export const THEME_OUTLINE = 'outline';
export const THEME_PLAIN = 'plain';

export default {
    mixins: [buttonOrLink],
    inheritAttrs: false,
    props: {
        theme: oneOf([THEME_DARK, THEME_LIGHT, THEME_PRIMARY, THEME_OUTLINE, THEME_PLAIN]).def(THEME_DARK),
    },
    computed: {
        classes() {
            return [this.theme];
        },
        plain() {
            return this.theme === THEME_PLAIN;
        },
        outline() {
            return this.theme === THEME_OUTLINE;
        },
    },
    methods: {
        onMouseEnter(e) {
            const hoverDir = this.getHoverDirection(e);

            this.setTransFormOrigin(hoverDir);

            if (hoverDir === 'top' || hoverDir === 'bottom') {
                this.$gsap.fromTo(
                    this.$refs.hoverBg,
                    { scaleX: 1, scaleY: 0 },
                    { scaleX: 1, scaleY: 1, duration: 0.3, ease: 'e1' }
                );
            } else if (hoverDir === 'left' || hoverDir === 'right') {
                this.$gsap.fromTo(
                    this.$refs.hoverBg,
                    { scaleX: 0, scaleY: 1 },
                    { scaleX: 1, scaleY: 1, duration: 0.3, ease: 'e1' }
                );
            }
        },
        onMouseLeave(e) {
            const hoverDir = this.getHoverDirection(e);

            this.setTransFormOrigin(hoverDir);

            if (hoverDir === 'top' || hoverDir === 'bottom') {
                this.$gsap.to(this.$refs.hoverBg, { scaleY: 0, duration: 0.3, ease: 'e1' });
            } else if (hoverDir === 'left' || hoverDir === 'right') {
                this.$gsap.to(this.$refs.hoverBg, { scaleX: 0, duration: 0.3, ease: 'e1' });
            }
        },
        getHoverDirection({ clientX, clientY }) {
            const rect = this.$el.getBoundingClientRect();
            const localX = Math.max(0, clientX - rect.x);
            const localY = Math.max(0, clientY - rect.y);

            // make hover direction-aware
            const hoverDirX = localX < 10 ? 'left' : localX > rect.width - 10 ? 'right' : 'center';
            const hoverDirY = localY < 10 ? 'top' : localY > rect.height - 10 ? 'bottom' : 'center';

            if (hoverDirX === 'center') {
                return hoverDirY;
            } else {
                return hoverDirX;
            }
        },
        setTransFormOrigin(dir) {
            this.$refs.hoverBg.style.transformOrigin = dir;
        },
    },
};
