
import { bool } from 'vue-types';
export default {
    props: {
        value: bool(),
        disabled: bool().def(false),
    },
    data() {
        return {
            checked: false,
        };
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.checked);
        },
    },
};
