import cdnURL from '@/utils/cdnURL';

/**
 * Generate the header image sequence objects
 */
const imageSequenceLength = 12;
const imgSequence = Array.from({ length: imageSequenceLength }, (value, index) => ({
  mobile: {
    src: `_cdn_/img/header-mob/${index + 1}.jpg`,
  },
  desktop: {
    src: `_cdn_/img/header-desk/${index + 1}.jpg`,
  },
}));

/**
 * Note that Nuxt Image module is used, and configured to use '_cdn_' alias (see nuxt.config.js)
 */
export default {
  homepage: {
    header: {
      imgSequence,
      image: {
        src: '_cdn_/img/1-header.jpg',
      },
      video: {
        sources: [
          {
            media: '(orientation: landscape)',
            src: cdnURL('/video/placeholder/header-landscape-2.webm'),
            type: 'video/webm',
          },
          {
            media: '(orientation: landscape)',
            src: cdnURL('/video/placeholder/header-landscape-2.mp4'),
            type: 'video/mp4',
          },
          {
            media: '(orientation: portrait)',
            src: cdnURL('/video/placeholder/header-portrait-2.webm'),
            type: 'video/webm',
          },
          {
            media: '(orientation: portrait)',
            src: cdnURL('/video/placeholder/header-portrait-2.mp4'),
            type: 'video/mp4',
          },
        ],
      },
    },
    modules: [
      {
        type: 'single-img',
        visual: {
          src: '_cdn_/img/2-1.jpg',
          focalPoint: [0.7, 0.3],
        },
        front: true,
        trigger: {
          id: 'show-scroll-btn',
          start: 'top center',
          toggleActions: 'restart none none reverse',
        },
      },
      {
        type: 'image-row',
        items: [
          {
            visual: {
              src: '_cdn_/img/2-2.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3',
            parallax: false,
            // parallaxRatio: 1.1,
          },
          {
            visual: {
              src: '_cdn_/img/2-3.jpg',
              focalPoint: [0.4, 0.5],
            },
            classes: 'col-span-full l:col-span-5 l:col-start-5 mt-1x',
            front: true,
          },
          {
            visual: {
              src: '_cdn_/img/2-4.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3 col-start-2 l:col-start-10',
            front: true,
            alignEnd: true,
            // parallaxRatio: 0.75,
          },
        ],
      },
      {
        type: 'image-row',
        items: [
          {
            visual: {
              src: '_cdn_/img/2-5.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3 l:col-span-6 extend-left',
          },
          {
            visual: {
              src: '_cdn_/img/2-6.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3 col-start-2 l:col-span-5 l:col-start-7',
            front: true,
            parallaxRatio: 1.3,
          },
        ],
      },
      {
        type: 'richText',
        body: '<p>You are so much more than people can see. Wear what you want to share.</p>',
        // define a scrolltrigger to run when this module scrolls into the viewport
        trigger: {
          id: 'move-logo',
          start: 'top 75%',
          toggleActions: 'restart none none reverse',
        },
        marginBottom: false,
      },
      {
        type: 'image-row',
        items: [
          {
            visual: {
              src: '_cdn_/img/2-8.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3 l:col-span-6',
          },
          {
            visual: {
              src: '_cdn_/img/2-7.jpg',
              focalPoint: [0.5, 0.5],
            },
            classes: 'col-span-3 col-start-2 l:col-span-6 l:col-start-7 mt-2x',
            front: true,
          },
        ],
        bgFill: true,
        marginBottom: false,
        padding: false,
      },
      {
        type: 'single-img',
        visual: {
          src: '_cdn_/img/2-10.jpg',
          focalPoint: [0.5, 0.5],
          front: true,
        },
        bgFill: true,
        marginBottom: false,
        padding: true,
      },
      {
        // full-width image that will stay pinned so the next module will scroll over it
        type: 'single-img',
        visual: {
          src: '_cdn_/img/2-9.jpg',
          focalPoint: [0.5, 0.5],
        },
        fullwidth: true,
        fullheight: true,
        useParallax: false,
        useReveal: false,
        marginBottom: false,
        trigger: {
          id: 'pinBeforeSubscribe',
          pin: true,
          pinSpacing: false,
          start: 'top top',
          end: 'bottom top',
        },
      },
      {
        type: 'product',
        id: 'product-scarf',
        front: true,
        productHandle: 'scarf',
        addToCart: 'Add to cart',
        footerNotes: [
          {
            icon: 'clock',
            text: 'Delivered within 2-5 days',
          },
          {
            icon: 'truck',
            text: 'Free shipping in The Netherlands in 2022',
          },
          {
            icon: 'secure',
            text: 'Secure payment',
          },
        ],
        trigger: {
          id: 'hide-scroll-btn',
          start: 'top bottom',
          toggleActions: 'restart none none reverse',
        },
      },
      // {
      //   type: 'subscribe',
      //   id: 'subscribe',
      //   heading: 'Launch early 2022',
      //   body: '<p>Be the first to get a Reveal Scarf. Subscribe to get notified and stay up to date. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis auctor ultricies gravida. Quisque tortor ligula, facilisis fringilla libero id, luctus varius magna.</p>',
      //   form: {
      //     placeholder: 'Your e-mail address',
      //     validationMessage: 'Enter a valid e-mail address',
      //     successMessage: "Success! We've sent you an e-mail",
      //     failMessage: 'An error occurred when trying to subscribe',
      //   },
      //   front: true,
      //   marginBottom: false,
      //   trigger: {
      //     id: 'hide-scroll-btn',
      //     start: 'top bottom',
      //     toggleActions: 'restart none none reverse',
      //   },
      // },
      // {
      //   type: 'image-row',
      //   bgSplitFill: true,
      //   items: [
      //     {
      //       visual: {
      //         src: '_cdn_/img/p-img-xs-3.jpg',
      //         focalPoint: [0.5, 0.5],
      //       },
      //       classes: 'hidden l:block col-span-3',
      //       parallaxRatio: 0.9,
      //     },
      //     {
      //       visual: {
      //         src: '_cdn_/img/p-img-s.jpg',
      //         focalPoint: [0.4, 0.5],
      //       },
      //       classes: 'col-span-6',
      //       front: true,
      //       parallaxRatio: 0,
      //     },
      //     {
      //       visual: {
      //         src: '_cdn_/img/p-img-xs-4.jpg',
      //         focalPoint: [0.5, 0.5],
      //       },
      //       classes: 'hidden l:block col-span-3',
      //       front: true,
      //       alignEnd: true,
      //       parallaxRatio: 0.75,
      //     },
      //   ],
      // },
    ],
    scrollDownButton: {
      label: 'Get your scarf',
      id: 'product-scarf',
    },
  },
  footer: {
    heading: 'New designs drop soon. Subscribe to stay updated.',
    contact: {
      heading: 'Reveals',
      address: ['Maliesingel 28A', '3581 BH, Utrecht', 'The Netherlands'],
      contact: [
        {
          type: 'email',
          label: 'info@reveals.clothing',
        },
        {
          type: 'phone',
          label: '+31 (0)30 280 7030',
        },
      ],
    },
    social: {
      heading: 'Find us on',
      items: [
        {
          label: 'Instagram',
          href: 'https://instagram.com',
        },
        {
          label: 'TikTok',
          href: 'https://www.tiktok.com/@reveals.clothing',
        },
      ],
    },
    legal: {
      items: [
        {
          label: 'Terms and conditions',
          to: '/terms-and-conditions',
        },
        {
          label: 'Shipping & Returns',
          to: '/return-policy',
        },
        {
          label: 'Privacy policy',
          to: '/privacy-policy',
        },
      ],
    },
    copyright: 'All Rights Reserved . Copyright © ' + new Date().getFullYear(),
    form: {
      placeholder: 'Your e-mail address',
      validationMessage: 'Enter a valid e-mail address',
      successMessage: 'Success!',
      failMessage: 'An error occurred when trying to subscribe',
    },
  },
};
