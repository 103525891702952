/**
 * Vue Formulate config
 */

// import { nl } from '@braid/vue-formulate-i18n';

export default {
  // plugins: [nl],
  classes: {
    // outer: (context, classes) => {
    //   return classes.concat([`theme-wrapper--${context.type}`]);
    // },
    input: 'input',
    error: 'error-msg text-red text-s-fluid--4 mt-fluid-2xs',
  },
  // @see: https://vueformulate.com/guide/validation/#validation-name-strategy
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  // no box decorators, see https://vueformulate.com/guide/inputs/types/box/#styling-box-inputs
  useInputDecorators: false,
  // this doesn't seem to work...
  library: {
    // text: {
    //   slotComponents: {
    //     // Use the string name of the globally registered component.
    //     suffix: 'ValidationIcon',
    //   },
    // },
    // checkbox: {
    //   slotComponents: {
    //     suffix: 'CustomCheckbox',
    //   },
    // },
  },
  /**
   * @see: https://vueformulate.com/guide/inputs/slots/#slot-components
   * this works, but will add the icon to all types...
   */
  slotComponents: {
    // Use the string name of the globally registered component.
    suffix: 'ValidationIcon',
  },
};
