const CUBIC = {
  'in-cubic': 'cubic-bezier(0.32, 0, 0.67, 0)',
  'out-cubic': 'cubic-bezier(0.33, 1, 0.68, 1)',
  'in-out-cubic': 'cubic-bezier(0.65, 0, 0.35, 1)',
};

const QUAD = {
  'in-quad': 'cubic-bezier(.55, .085, .68, .53)',
  'out-quad': 'cubic-bezier(.25, .46, .45, .94)',
  'in-out-quad': 'cubic-bezier(.455, .03, .515, .955)',
};

// const QUART = {
//   'in-quart': 'cubic-bezier(0.5, 0, 0.75, 0)',
//   'out-quart': 'cubic-bezier(0.25, 1, 0.5, 1)',
//   'in-out-quart': 'cubic-bezier(0.76, 0, 0.24, 1)',
// };

const EXPO = {
  'in-expo': 'cubic-bezier(0.7, 0, 0.84, 0)',
  'out-expo': 'cubic-bezier(0.16, 1, 0.3, 1)',
  'in-out-expo': 'cubic-bezier(0.87, 0, 0.13, 1)',
};

// project-specific
const CUSTOM_EASES = {
  e1: '.55, 0, .18, 1', // aka 'primary', see https://cubic-bezier.com/#.55,0,.18,1
  e2: '.16, .44, .21, 1', //  aka 'secondary', see https://cubic-bezier.com/#.16,.44,.21,1
  e3: '.65, 0, .83, .83', // aka 'tertiary', see https://cubic-bezier.com/#.65,0,.83,.83
};

// TODO: how to move this to a util that can be used in the project as well...
function mapObject(obj, fn) {
  return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

const CUSTOM = mapObject(CUSTOM_EASES, (value) => `cubic-bezier(${value})`);

module.exports = {
  easings: CUSTOM_EASES, // needed for js / GSAP CustomEase
  transitionTimingFunction: {
    ...CUBIC,
    ...QUAD,
    ...EXPO,
    ...CUSTOM,
  },
  transitionDuration: {
    default: '300ms',
    200: '200ms',
    260: '260ms',
    300: '300ms',
    400: '400ms',
    500: '500ms',
    700: '700ms',
    1000: '1000ms',
  },
  transitionDelay: {
    0: '0ms',
    50: '50ms',
    100: '100ms',
    150: '150ms',
    200: '200ms',
    250: '250ms',
    300: '300ms',
  },
};
