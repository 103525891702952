import { render, staticRenderFns } from "./SubscribeForm.vue?vue&type=template&id=013b6ff4&scoped=true&"
import script from "./SubscribeForm.vue?vue&type=script&lang=js&"
export * from "./SubscribeForm.vue?vue&type=script&lang=js&"
import style0 from "./SubscribeForm.vue?vue&type=style&index=0&id=013b6ff4&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013b6ff4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomFormulateInput: require('/workspace/components/molecules/CustomFormulateInput.vue').default,BaseIcon: require('/workspace/components/atoms/BaseIcon.vue').default,ArrowIcon: require('/workspace/components/atoms/ArrowIcon.vue').default,IconButton: require('/workspace/components/molecules/IconButton.vue').default})
