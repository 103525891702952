import Vue from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { SplitText } from 'gsap/SplitText';
// import { Flip } from 'gsap/Flip';
import { CustomEase } from 'gsap/CustomEase';

import Easings from '@/constants/Easings';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(SplitText);
// gsap.registerPlugin(Flip);
gsap.registerPlugin(CustomEase);

for (const key in Easings) {
  CustomEase.create(key, Easings[key]);
}

gsap.defaults({
  ease: 'e1',
});

const GSAP = {
  install(Vue, options) {
    Vue.prototype.$gsap = gsap;
    Vue.prototype.$ScrollTrigger = ScrollTrigger;
    // Vue.prototype.$Flip = Flip;
  },
};

Vue.use(GSAP);
