
import { array, arrayOf, shape, string } from 'vue-types';

import hasScrollTriggers from '@/mixins/hasScrollTriggers';

export default {
    mixins: [hasScrollTriggers],
    inheritAttrs: false,
    props: {
        heading: string(),
        contact: shape({
            heading: string(),
            address: arrayOf(string()),
            contact: arrayOf(
                shape({
                    type: string(),
                    label: string(),
                })
            ),
        }),
        social: shape({
            heading: string(),
            items: arrayOf(
                shape({
                    label: string(),
                    href: string(),
                })
            ),
        }),
        legal: shape({
            items: arrayOf(
                shape({
                    label: string(),
                    to: string(),
                })
            ),
        }),
        copyright: string(),
        images: array(),
        form: shape({
            placeholder: string(),
            validationMessage: string(),
            successMessage: string(),
            failMessage: string(),
        }),
    },
    data() {
        return {
            playIntro: false,
        };
    },
    computed: {},
    methods: {
        setupScrollTriggers() {
            const tl = this.$gsap.timeline({
                scrollTrigger: {
                    scroller: this.scrollingElement,
                    trigger: this.$refs.heading,
                    start: 'top bottom-=100',
                    onEnter: () => {
                        this.playIntro = true;
                    },
                },
            });
            this.addScrollTrigger(tl);
        },
        getLink(contactItem) {
            switch (contactItem.type) {
                case 'phone':
                    return `tel:${contactItem.label}`;
                case 'email':
                    return `mailto:${contactItem.label}`;
            }
            return null;
        },
    },
};
