
import { string } from 'vue-types';

export default {
    props: {
        placeholder: string(),
        validationMessage: string(),
        successMessage: string(),
        failMessage: string(),
    },
    data() {
        return {
            formValues: {
                name: '',
                email: '',
            },
            validation: {},
            done: false,
            errorMessage: null,
        };
    },
    computed: {
        valid() {
            return this.validation?.hasErrors === false;
        },
    },
    mounted() {
        if (this.user) {
            const { email } = this.user;

            this.formValues = {
                ...this.formValues,
                email,
            };
        }
    },
    methods: {
        onValidation(value) {
            this.validation = value;
        },
        submitSuccess() {
            this.done = true;

            this.$gtag('event', 'form', {
                event_category: 'subscribe',
                event_label: 'success',
            });
        },
        submitFailure(errorObject, exception) {
            this.errorMessage = this.failMessage;

            if (process.env.DEV) {
                console.error(exception);
                console.error(errorObject);
            } else {
                this.$sentry.captureException(exception);
            }
        },
        async onSubmit({ name, email }) {
            if (this.done) {
                return;
            }

            try {
                await this.$axios.put(`${window.location.origin}/subscribe`, { email, name });

                this.submitSuccess();
            } catch (e) {
                if (process.env.DEV) {
                    console.error(e);
                } else {
                    this.$sentry.captureException(e);
                }

                this.submitFailure(e.response?.data?.error, e);
            }
        },
    },
};
