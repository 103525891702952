
// import { SteppedEase } from 'gsap';

// const BLINK_COUNT = 5;

export default {
    data() {
        return {
            loadProgress: 0,
            revealLogo: false,
        };
    },
    computed: {
        isDev() {
            return this.$store.getters.isDev;
        },
        isReady() {
            return this.$store.state.isReady;
        },
        outroDone() {
            return this.$store.state.preloader.outroDone;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.playAnimation();
        });

        if (this.isDocumentReady()) {
            this.$store.commit('isReady');
        } else {
            document.addEventListener('readystatechange', this.readyStateChangeHandler);
        }
    },
    beforeDestroy() {
        document.removeEventListener('readystatechange', this.readyStateChangeHandler);
    },
    methods: {
        isDocumentReady() {
            return document.readyState === 'complete';
        },
        readyStateChangeHandler() {
            // console.log('readystatechange', document.readyState);
            if (this.isDocumentReady()) {
                this.$store.commit('isReady');
            }
        },
        playAnimation() {
            const tl = this.$gsap.timeline();
            tl.add(this.getIntro());
            tl.add(this.getOutro(), 2);
        },
        getIntro() {
            const tl = this.$gsap.timeline();

            // tl.to(this.$refs.logo, {
            //   opacity: 0,
            //   ease: SteppedEase.config(1),
            //   duration: 0.2,
            //   repeat: BLINK_COUNT,
            //   repeatDelay: 0.2,
            // });

            return tl;
        },
        getOutro() {
            const tl = this.$gsap.timeline({
                onStart: () => {
                    this.$store.commit('preloader/outroStarted');
                },
                onComplete: () => {
                    this.$store.commit('preloader/outroDone');
                    this.$nuxt.$emit('pageIntro');
                },
            });

            return tl;
        },
    },
};
