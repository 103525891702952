
export default {
    inheritAttrs: false,
    data() {
        return {
            hasFocus: false,
        };
    },
    computed: {
        classes() {
            return {
                'has-focus': this.hasFocus,
            };
        },
    },
    methods: {
        onFocus(e) {
            this.hasFocus = true;
        },
        onBlur(e) {
            this.hasFocus = false;
        },
    },
};
