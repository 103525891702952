export const state = () => ({
  outroStarted: false,
  outroDone: false,
});

export const getters = {
  use(state, getters, rootState, rootGetters) {
    if (rootGetters.isDev) {
      // set to false to not have to wait for the preloader during dev
      return true;
    }
    return true;
  },
  done(state, getters) {
    return state.outroDone || !getters.use;
  },
};

export const mutations = {
  outroStarted(state) {
    state.outroStarted = true;
  },
  outroDone(state) {
    state.outroDone = true;
  },
};
